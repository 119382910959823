import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import logoShiloh from "../images/logoShiloh.png";
import logoCement from "../images/logoCement.png";


function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section className="text-center">
      <img
          src={logoShiloh}
          className="block mx-auto w-1/2"
          alt="Cat and human sitting on a couch"
        />
         
         <img
          src={logoCement}
          className="block mx-auto w-1/2"
          alt="Cat and human sitting on a couch"
        />

       
      </section>
      <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or 0773 386 695
        </h4>
    </Layout>
  );
}

export default AboutPage;
